import { Divider, Stack } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../components/footer";
import Navbar from "../components/navbar";
import SocialIcons from "../components/socialIcons";
// import { Helmet } from "react-helmet-async";
// import SEO from "./SEO";

const MainLayout = () => {
  return (
    <>
      {/* <Helmet>
        <link rel="icon" href={"https://www.daddyomarjeng.com/favicon.ico"} />
        <link rel="manifest" href="https://www.daddyomarjeng.com/manifest.json" />

        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />

        <title>DOJ - Daddy Omar Jeng</title>
        <meta property="og:title" content={`DOJ - Daddy Omar Jeng`} />
        <meta
          property="og:description"
          content={
            "Daddy Omar Jeng(DOJ). Full Stack Software Engineer, Mobile App Developer, BSc Information Systems from University of The Gambia, HND in Computer Science, Gambian Software Engineer, freelancer."
          }
        />
        <meta
          property="og:keywords"
          content={`DOJ, Daddy Omar Jeng, Omar Jeng, Omar, Jeng, Omar Jeng Gambia, DOJ Gambia, Omar Gambia, Jeng Gambia, Full Stack Software Engineer, Mobile App Developer, Gambian Software Engineer, freelancer`}
        />
        <meta property="og:author" content={"DOJ - Daddy Omar Jeng"} />
        <meta property="og:image" content={"https://www.daddyomarjeng.com/doj.jpg"} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`DOJ - Daddy Omar Jeng`} />
        <meta
          name="twitter:description"
          content={
            "Daddy Omar Jeng(DOJ). Full Stack Software Engineer, Mobile App Developer, BSc Information Systems from University of The Gambia, HND in Computer Science, Gambian Software Engineer, freelancer."
          }
        />
        <meta name="twitter:image" content={"https://www.daddyomarjeng.com/doj.jpg"} />

        <meta property="og:url" content="https://www.daddyomarjeng.com" />
        <link rel="canonical" href="https://www.daddyomarjeng.com" />
        <link rel="alternate" href="https://www.daddyomarjeng.com/" />
      </Helmet> */}
      <Box mt={2}>
        <Navbar />
        <Stack
          direction="row"
          gap={5}
          sx={{
            minHeight: "80vh",
          }}
        >
          <Outlet />
        </Stack>
        <Divider
          color="#ff5757"
          sx={{
            backgroundColor: "#ff5757",
            position: "fixed",
            bottom: 40,
            left: 0,
            right: 0,
          }}
        />
        <Footer />
        <SocialIcons />
      </Box>
    </>
  );
};

export default MainLayout;
